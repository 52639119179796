<template>
  <div class="box">
    <SATMenu active="2" />
    <div class="container">
      <h1 class="sat_left">
        <p>{{ $t("sat.SAT_Results") }}</p>
      </h1>
      <template v-if="false">
        <a :href="'/testresults/combine'" class="combine-button">
          <el-button type="success" size="small">
            <i class="far fa-object-group"></i>
            {{ $t("sat.Combine Tests") }}
          </el-button>
        </a>
        <hr />
        <el-form
          v-if="isRoleAdmin()"
          :inline="true"
          :model="search"
          class="demo-form-inline"
          style="margin-top:20px"
          size="mini"
        >
          <el-form-item label="Student">
            <el-input
              v-model="search.user_keyword"
              placeholder="Name/Username/Email"
            ></el-input>
          </el-form-item>
          <el-form-item label="Test Name">
            <el-input
              v-model="search.test_title"
              placeholder="Test Name"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-button-item">
            <el-button type="success" @click="searchPage" class="search-button"
              >Search</el-button
            >
          </el-form-item>
        </el-form>
      </template>
      <el-menu :default-active="active" class="el-menu-demo" mode="horizontal">
        <el-menu-item index="1" class="menu-item"
          ><b
            ><router-link
              :to="{
                name: 'SATResults',
                query: {
                  type: 'composePractices'
                }
              }"
              class="linkInTab"
              >{{ $t("sat.Full Tests") }}</router-link
            ></b
          ></el-menu-item
        >
        <el-menu-item index="2" class="menu-item"
          ><b
            ><router-link
              :to="{
                name: 'SATResults',
                query: {
                  type: 'singlePractice'
                }
              }"
              class="linkInTab"
            >
              {{ $t("sat.Partial Tests") }}</router-link
            ></b
          ></el-menu-item
        >
        <el-menu-item index="3" class="menu-item"
          ><b
            ><router-link
              :to="{
                name: 'SATResults',
                query: {
                  type: 'drill'
                }
              }"
              class="linkInTab"
              >{{ $t("sat.Drill Practice") }}</router-link
            ></b
          ></el-menu-item
        >
        
        
        <!-- <el-menu-item index="4" class="menu-item"
          ><b
            ><router-link
              :to="{
                name: 'SATResults',
                query: {
                  type: 'adaptive'
                }
              }"
              class="linkInTab"
              >{{ $t("sat.Adaptive Tests") }}</router-link
            ></b
          ></el-menu-item
        > -->
      </el-menu>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :page-count="6"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-radio-group :value="isFinished" @input="changeTest" size="medium">
        <el-radio-button :label="null">All</el-radio-button>
        <el-radio-button :label="1">{{
          $t("courses.completed")
        }}</el-radio-button>
        <el-radio-button :label="0">{{
          $t("courses.Incomplete")
        }}</el-radio-button>
      </el-radio-group>
      <TestResultsTable
        :type="type"
        :results="results"
        role="user"
      ></TestResultsTable>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :page-count="6"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SAT from "@/apis/sat";
import TestResultsTable from "@/views/satTestResultsPage/components/TestResultsTable";
import SATMenu from "@/views/satList/components/SATMenu.vue";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: "SAT TestResults - " + this.CompanyName
    };
  },

  components: {
    TestResultsTable,
    SATMenu
  },

  mixins: [roleMixin],

  props: [],
  data() {
    return {
      search: {
        user_keyword: null,
        test_title: null
      },
      active: null,
      results: null,
      resultsTotal: 0,
      pageSize: 0,
      isAdmin: null
    };
  },
  computed: {
    isFinished() {
      let page = null;
      if (parseInt(this.$route.query.is_finished) > -1) {
        page = parseInt(this.$route.query.is_finished);
      }
      return page;
    },
    ...mapState("user", ["lang", "profile"]),
    page() {
      let page = 1;
      if (this.$route.query.page) {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    type() {
      if (this.$route.query.type) {
        return this.$route.query.type;
      } else {
        return "singlePractice";
      }
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.query.user_keyword) {
      this.search.user_keyword = this.$route.query.user_keyword;
    }
    if (this.$route.query.test_title) {
      this.search.test_title = this.$route.query.test_title;
    }
    this.getResults();
  },

  methods: {
    changeTest(val) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: 1, is_finished: val }
      });
    },
    async getResults() {
      // console.log(this.isRoleAdmin());
      let results = null;
      if (this.type === "composePractices") {
        results = await SAT.getComposePracticeUserExam({
          page: this.page,
          is_finished: this.isFinished
        });
        this.active = "1";
      } else if (this.type === "adaptive") {
        results = await SAT.getComposePracticeUserExam({
          page: this.page,
          is_finished: this.isFinished
        });
        this.active = "4";
      } else if (this.type === "singlePractice") {
        results = await SAT.getPracticeUserExam({
          page: this.page,
          practice_type: null,
          is_finished: this.isFinished
        });
        this.active = "2";
      } else if (this.type === "drill") {
        results = await SAT.getPracticeUserExam({
          page: this.page,
          practice_type: "drill",
          is_finished: this.isFinished
        });
        this.active = "3";
      }
      this.results = results.user_exams.data;
      this.pageSize = results.user_exams.per_page;
      this.resultsTotal = results.user_exams.total;
    },
    setPage(page) {
      this.routerPush({
        name: "SATResults",
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    searchPage() {
      this.routerPush({
        name: "SATResults",
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title
        }
      });
    }
  }
};
</script>

<style scoped>
.box .container {
  padding-top: 0;
}
.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0px;
}
.linkInTab {
  color: inherit !important;
  display: block;
}

.linkInTab::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
}

.linkInTab:hover {
  text-decoration: none;
}
.menu-item {
  height: 40px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .box .container {
    padding: 0 15px 20px 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .sat_left {
    margin: 0;
    margin-top: 10px;
  }
  .sat_left > p {
    font-weight: 700;
    font-size: 24px;
  }
}
</style>
