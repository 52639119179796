var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('SATMenu',{attrs:{"active":"2"}}),_c('div',{staticClass:"container"},[_c('h1',{staticClass:"sat_left"},[_c('p',[_vm._v(_vm._s(_vm.$t("sat.SAT_Results")))])]),(false)?[_c('a',{staticClass:"combine-button",attrs:{"href":'/testresults/combine'}},[_c('el-button',{attrs:{"type":"success","size":"small"}},[_c('i',{staticClass:"far fa-object-group"}),_vm._v(" "+_vm._s(_vm.$t("sat.Combine Tests"))+" ")])],1),_c('hr'),(_vm.isRoleAdmin())?_c('el-form',{staticClass:"demo-form-inline",staticStyle:{"margin-top":"20px"},attrs:{"inline":true,"model":_vm.search,"size":"mini"}},[_c('el-form-item',{attrs:{"label":"Student"}},[_c('el-input',{attrs:{"placeholder":"Name/Username/Email"},model:{value:(_vm.search.user_keyword),callback:function ($$v) {_vm.$set(_vm.search, "user_keyword", $$v)},expression:"search.user_keyword"}})],1),_c('el-form-item',{attrs:{"label":"Test Name"}},[_c('el-input',{attrs:{"placeholder":"Test Name"},model:{value:(_vm.search.test_title),callback:function ($$v) {_vm.$set(_vm.search, "test_title", $$v)},expression:"search.test_title"}})],1),_c('el-form-item',{staticClass:"search-button-item"},[_c('el-button',{staticClass:"search-button",attrs:{"type":"success"},on:{"click":_vm.searchPage}},[_vm._v("Search")])],1)],1):_vm._e()]:_vm._e(),_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.active,"mode":"horizontal"}},[_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"1"}},[_c('b',[_c('router-link',{staticClass:"linkInTab",attrs:{"to":{
              name: 'SATResults',
              query: {
                type: 'composePractices'
              }
            }}},[_vm._v(_vm._s(_vm.$t("sat.Full Tests")))])],1)]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"2"}},[_c('b',[_c('router-link',{staticClass:"linkInTab",attrs:{"to":{
              name: 'SATResults',
              query: {
                type: 'singlePractice'
              }
            }}},[_vm._v(" "+_vm._s(_vm.$t("sat.Partial Tests")))])],1)]),_c('el-menu-item',{staticClass:"menu-item",attrs:{"index":"3"}},[_c('b',[_c('router-link',{staticClass:"linkInTab",attrs:{"to":{
              name: 'SATResults',
              query: {
                type: 'drill'
              }
            }}},[_vm._v(_vm._s(_vm.$t("sat.Drill Practice")))])],1)])],1),_c('div',{staticClass:"text-center",staticStyle:{"margin":"15px 0"}},[_c('el-pagination',{attrs:{"background":"","page-size":_vm.pageSize,"current-page":_vm.page,"total":_vm.resultsTotal,"page-count":6,"layout":"prev, pager, next"},on:{"current-change":_vm.setPage}})],1),_c('el-radio-group',{attrs:{"value":_vm.isFinished,"size":"medium"},on:{"input":_vm.changeTest}},[_c('el-radio-button',{attrs:{"label":null}},[_vm._v("All")]),_c('el-radio-button',{attrs:{"label":1}},[_vm._v(_vm._s(_vm.$t("courses.completed")))]),_c('el-radio-button',{attrs:{"label":0}},[_vm._v(_vm._s(_vm.$t("courses.Incomplete")))])],1),_c('TestResultsTable',{attrs:{"type":_vm.type,"results":_vm.results,"role":"user"}}),_c('div',{staticClass:"text-center",staticStyle:{"margin":"15px 0"}},[_c('el-pagination',{attrs:{"background":"","page-size":_vm.pageSize,"current-page":_vm.page,"total":_vm.resultsTotal,"page-count":6,"layout":"prev, pager, next"},on:{"current-change":_vm.setPage}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }